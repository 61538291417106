<script setup>
import { defineProps, defineEmits } from 'vue';
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
locale.value = localStorage.getItem('lang')||'en';

const props = defineProps({
  info: Array,
});

const emits = defineEmits(['goToActivity']);

const handleButtonClick = (id) => {
  emits('goToActivity', id);
};

</script>

<template>
  <div id="activity-list" class="main_content_item" v-if="info">
    
    <div class="item" v-for="(item,index) in info" :key="item.id">
      <h4>Activity {{index+1}}</h4>
      <div class="activity">
        <div class="activity_item">
          <div class="label">
            <h5 v-html="t('eventName')" />
          </div>
          <div class="text">
            <p>
              {{item.name}}
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5 v-html="t('state')" />
          </div>
          <div class="text">
            <p v-if="item.status_type==0">
              Not Participate
            </p>
            <p v-if="item.status_type==1">
              Participate
            </p>
            <p v-if="item.status_type==2">
              Settled
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5 v-html="t('days')" />
          </div>
          <div class="text">
            <p>
              {{item.days}}{{ t('days') }}
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5 v-html="t('profitable')" />
          </div>
          <div class="text">
            <p>
              {{ item.join_amount }} ETH
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5 v-html="t('bouns_amount')" />
          </div>
          <div class="text">
            <p>
              {{ item.bouns_amount }} ETH
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5 v-html="t('join')" />
          </div>
          <div class="text">
            <button v-if="item.status_type == 0" @click="handleButtonClick(item.id)">Go</button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>