<script setup>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
locale.value = localStorage.getItem('lang')||'en';

const qa = ref([
  {
    id: 1,
    open: true,
    q: t('qa_title01'),
    a:t('qa_content01')
  },
  {
    id: 2,
    open: false,
    q: t('qa_title02'),
    a:t('qa_content02')
  },
  {
    id: 3,
    open: false,
    q: t('qa_title03'),
    a:t('qa_content03')
  },
  {
    id: 4,
    open: false,
    q: t('qa_title04'),
    a:t('qa_content04')
  },
  {
    id: 5,
    open: false,
    q: t('qa_title05'),
    a:t('qa_content05')
  },
  {
    id: 6,
    open: false,
    q: t('qa_title06'),
    a:t('qa_content06')
  },
  {
    id: 7,
    open: false,
    q: t('qa_title07'),
    a:t('qa_content07')
  },
  {
    id: 8,
    open: false,
    q: t('qa_title08'),
    a:t('qa_content08')
  },
  {
    id: 9,
    open: false,
    q: t('qa_title09'),
    a:t('qa_content09')
  },
  {
    id: 10,
    open: false,
    q: t('qa_title10'),
    a:t('qa_content10')
  },
]);

function open_btn(data){

  qa.value.forEach((item)=>{
    item.open = false;
  })

  qa.value[data].open = true;
}

onMounted(() => {

});
</script>

<template>
  <div class="main_content_item">

    <div class="item">
      <h4 v-html="t('PoolData')" />
      <ul class="content">
        <li>
          <div class="label" v-html="t('totalOutput')" />
          <div class="item_content color">ETH</div>
        </li>
        <li>
          <div class="label" v-html="t('validNode')" />
          <div class="item_content"></div>
        </li>
        <li>
          <div class="label"  v-html="t('participant')" />
          <div class="item_content"></div>
        </li>
        <li>
          <div class="label" v-html="t('userRevenue')" />
          <div class="item_content">USDT</div>
        </li>
      </ul>
    </div>

    <div class="title">
      <h3 v-html="t('mining')" />
      <p v-html="t('miningLiquidity')" />
    </div>

    <div id="supercomputing_miner" class="item">
      <h4 v-html="t('supercomputingMiner')" />
      <ul class="content">

        <li>
          <div class="label top_title">
            Miner
          </div>
          <div class="item_content top_title">
            <div class="top_item">
              Value
            </div>
            <div class="top_item">
              Yield
            </div>
          </div>
        </li>

        <li>
          <div class="label">FPGA</div>
          <div class="item_content bottom">
            <div class="bottom_item">
              500~9999
            </div>
            <div class="bottom_item">
              0.46% ~ 0.53%
            </div>
          </div>
        </li>

        <li>
          <div class="label">IPFS</div>
          <div class="item_content bottom">
            <div class="bottom_item">
              10000~99999
            </div>
            <div class="bottom_item">
              0.95% ~ 1.02%
            </div>
          </div>
        </li>

        <li>
          <div class="label">ASIC</div>
          <div class="item_content bottom">
            <div class="bottom_item">
              50w+
            </div>
            <div class="bottom_item">
              1.98% ~ 2.05%
            </div>
          </div>
        </li>

        <li>
          <div class="label">Valid node</div>
          <div class="item_content bottom">
            <div class="bottom_item">
              500~9999
            </div>
            <div class="bottom_item">
              0.46% ~ 0.53%
            </div>
          </div>
        </li>

      </ul>
    </div>

    <div class="item">
      <h4 v-html="t('userOutput')" />

      <ul class="content">
        <li>
          <div class="label top_title" v-html="t('address')" />
          <div class="item_content top_title">
            <div class="top_item" v-html="t('qantity')" />
          </div>
        </li>
      </ul>

        <div class="marquee-container">
        <ul class="content marquee">
          <li>0x4838b106fce9647bdf1e7877bf73ce8b0bad5f97</li>
          <li>0x388c818ca8b9251b393131c08a736a67ccb19297</li>
          <li>0xf7858da8a6617f7c6d0ff2bcafdb6d2eedf64840</li>
          <li>0xcba6ae76d8c7228c6f4a59de49ae7a18355dc324</li>
          <li>0x82d52f0864af08ee9e79e6f128e9657072a712df</li>
          <li>0xfae103dc9cf190ed75350761e95403b7b8afa6c0</li>
          <li>0x4c9af439b1a6761b8e549d8d226a468a6b2803a8</li>
          <li>0xdac17f958d2ee523a2206206994597c13d831ec7</li>
          <li>0x808f49d11bd7319e160f2eb0fcc8ee0eae8cdd7e</li>
          <li>0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9</li>
          <li>0x0d0707963952f2fba59dd06f2b425ace40b492fe</li>
          <li>0x4a230546422e9ccd611e111be0f973ab17041820</li>
          <li>0x935df98cc7326298b704fea1cf9b678b71189f80</li>
          <li>0xc5a12a0de22c52a663364e68610992b4ba485596</li>
          <li>0xca3cff7ffc91409c57c17e6f3f096e7a691bb905</li>
          <li>0x205423b83edb17a50bb2e5a67eb16bead245eac4</li>
          <li>0xddbf2cc47dbacf2a4579fb8f37deee020cadda0d</li>
          <li>0xdc66d29db2d3f20e23d2ff6fe025e0e31b82e322</li>
          <li>0x9726581cdbfb46ea131d843bb4bbe9e66483ab1b</li>
          <li>0x397299c5163a7f0e57f6728bf55f42881fada3e3</li>
          <li>0xf31de20692731b65f24765ce87c637f5c3aa8b0c</li>
          <li>0x7fa741b75bdd05f0419c8a6bbbf4d462856e1930</li>
          <li>0xf9f4ce209eabc701faea14063551742207063568</li>
          <li>0xfb5cd43f9435024cbe763b87e2aee4cf4140470c</li>
          <li>0xb8cd225ea9478e29d89a062a6477b6600169fa60</li>
          <li>0xbe4ab86f7e3040233c8a8c5ef28e3162b7b31a37</li>
          <li>0x4a763f52626077fd7ef699579b8456bfa5651c1e</li>
          <li>0x5591dcc1591d0af5e21d15d53460f71d21dd3378</li>
          <li>0xe86ff33d4450d9283b353cc6944f91060e365a39</li>
          <li>0xf04b3886a8bf0b80fdb227c8cd51299c19936d89</li>
        </ul>
      </div>
    </div>

    <div class="item">
      <h4 v-html="t('guideVideo')" />
      <div class="video">
        <div class="video_content">
          <video src="@/assets/video/d95dd301.mp4"></video>
        </div>
      </div>
    </div>

    <div class="title">
      <h3 v-html="t('helpCenter')" />
      <p  v-html="t('hopeItHelpsYou')" />
    </div>

    <div class="item">
      <div id="qa">
        <ul>
          <li v-for="(item, index) in qa" :class="{active:item.open}" :key="item.id" :data-index="index">
            <div class="qa_title" @click="open_btn(index)">
              <span>{{ item.q }}</span><i />
            </div>
            <div class="qa_content">
              <p>{{ item.a }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="title">
      <h3>
        Audit report
      </h3>
      <p>
        We have a secure audit report
      </p>
    </div>

    <div class="item">
      <div id="audit">
        <div class="item_audit">
          <img src="@/assets/img/audit1.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="@/assets/img/audit2.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="@/assets/img/audit3.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="@/assets/img/audit4.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="@/assets/img/audit5.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="@/assets/img/audit6.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="@/assets/img/audit7.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="@/assets/img/audit8.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="@/assets/img/audit9.png" alt="pic">
        </div>
      </div>
    </div>

  </div>
</template>
