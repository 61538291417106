<script setup>
import { ref, defineProps, defineEmits } from "vue";

import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
locale.value = localStorage.getItem('lang')||'en';

const son_menu = ref(0);
const record_menu = ref(0)

const curr = ref(null);

const emits = defineEmits(['exchangeBtn']);

const accountButtonClick = (name, val, type) => {
  emits('exchangeBtn', name, val, type);
};

const props = defineProps({
  info: String,
  lang: Object,
  activity_list: Array,
  exchange_list: Array,
  withdraw_list: Array,
  exRateETHtoUSDT: String,
});

console.log("My account2",props.info);
</script>

<template>
  <div class="main_content_item">
    <div class="item">
      <h4 v-html="t('myAccount')" />
      <ul class="content" v-if="info">
        <li>
          <div class="label" v-html="t('walletBalance')" />
          <div class="item_content color">{{ info.wallet_balance }} USDT</div>
        </li>
        <li>
          <div class="label" v-html="t('exchangeable')" />
          <div class="item_content">{{ info.bonus_balance }} ETH</div>
        </li>
        <li>
          <div class="label" v-html="t('withdrawable')" />
          <div class="item_content">{{ info.usdt_balance }} USDT</div>
        </li>
      </ul>
    </div>

    <div class="item">
      <div id="son_label">
        <div id="exchange_btn" class="son_label_item" @click="son_menu = 0" :class="{ active: son_menu == 0 }">
          <h3 v-html="t('exchange_tab')" />
        </div>
        <div id="withdraw_btn" class="son_label_item" @click="son_menu = 1" :class="{ active: son_menu == 1 }">
          <h3 v-html="t('withdraw_tab')" />
        </div>
        <div id="record_btn" class="son_label_item" @click="son_menu = 2" :class="{ active: son_menu == 2 }">
          <h3 v-html="t('record_tab')" />
        </div>
      </div>
      <div id="son_content">

        <h4 v-if="son_menu == 0" v-html="t('exchange_title')" />
        <h4 v-else-if="son_menu == 1" v-html="t('withdraw_title')" />
        <h4 v-else v-html="t('record_title')" />

        <div class="change_currency">

          <div v-if="son_menu == 0" class="change_currency_content_item">
            <div class="change_currency_item">
              <input type="text" v-model="curr" />
              <div class="change_icon">
                <img src="@/assets/img/icon/transfer.svg" alt="icon" />
              </div>
              <div class="currency">
                <img src="@/assets/img/icon/usdt.png" alt="currency_icon" />
                <span> USDT </span>
                <div class="conversion">1ETH ： {{ exRateETHtoUSDT }}USDT</div>
              </div>
            </div>
            <div class="change_btn">
              <button id="change_btn" @click="accountButtonClick('exchange', curr, 'A'); curr = '';"
                v-html="t('exchange_btn')" />
              <button id="all_change_btn" @click="accountButtonClick('exchange', curr, 'B'); curr = '';"
                v-html="t('exchangeAll_btn')" />
              <p v-html="t('exchange_text')" />
            </div>
          </div>

          <div v-else-if="son_menu == 1" class="change_currency_content_item">
            <div class="change_currency_item">
              <input type="text" v-model="curr" />
              <div class="change_icon">
                <img src="@/assets/img/icon/transfer.svg" alt="icon" />
              </div>
              <div class="currency">
                <img src="@/assets/img/icon/usdt.png" alt="currency_icon" />
                <span> USDT </span>
                <div class="conversion">1ETH ： {{ exRateETHtoUSDT }}USDT</div>
              </div>
            </div>
            <div class="change_btn">
              <button @click="accountButtonClick('withdraw', curr, 'A'); curr = '';" v-html="t('withdraw_btn')" />
              <button @click="accountButtonClick('withdraw', curr, 'b'); curr = '';" v-html="t('withdrawAll_btn')" />
              <p v-html="t('withdraw_text')" />
            </div>
          </div>

          <div v-else class="change_currency_content_item">
            <div id="activity-list" class="main_content_item">
              <div class="item">
                <div id="record_menu_label">
                  <div @click="record_menu = 0" class="label_item" :class="{ active: record_menu == 0 }"
                    v-html="t('recordExchange')" />
                  <div @click="record_menu = 1" class="label_item" :class="{ active: record_menu == 1 }"
                    v-html="t('recordWithdraw')" />
                  <div @click="record_menu = 2" class="label_item" :class="{ active: record_menu == 2 }"
                    v-html="t('recordReward')" />
                </div>
                <div id="record_content">
                    <div class="content_item" v-if="record_menu == 0">
                      <div class="item" v-for="(item, index) in exchange_list" :key="item.id">
                        <!-- <h4>Exchange {{ index + 1 }}</h4> -->
                        <div class="activity">
                          <div class="activity_item">
                            <div class="label">
                              <h5>{{ item.date }}</h5>
                            </div>
                            <div class="text">
                              <p>

                              </p>
                            </div>
                          </div>
                          <div class="activity_item">
                            <div class="label">
                              <h5 v-html="t('amountOfEthExchangend')" />
                            </div>
                            <div class="text">
                              <p>
                                {{ item.amount_eth }}
                              </p>
                            </div>
                          </div>
                          <div class="activity_item">
                            <div class="label">
                              <h5 v-html="t('amountOfUsdtExchangend')" />
                            </div>
                            <div class="text">
                              <p>
                                {{ item.amount_usdt }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="content_item" v-else-if="record_menu == 1">
                      <div class="item" v-for="(item, index) in withdraw_list" :key="item.id">
                        <!-- <h4>Withdraw {{ index + 1 }}</h4> -->
                        <div class="activity">
                          <div class="activity_item">
                            <div class="label">
                              <h5>{{ item.date }}</h5>
                            </div>
                            <div class="text">
                              <p>
                              </p>
                            </div>
                          </div>
                          <div class="activity_item">
                            <div class="label">
                              <h5 v-html="t('withdrawalAomount')" />
                            </div>
                            <div class="text">
                              <p>
                                {{ item.amount }}
                              </p>
                            </div>
                          </div>
                          <!-- <div class="activity_item">
                          <div class="label">
                            <h5>withdrawal address</h5>
                          </div>
                          <div class="text">
                            <p>
                              {{ item.status_type }}
                            </p>
                          </div>
                        </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="content_item" v-else-if="record_menu == 2">
                      <div class="item" v-for="(item, index) in activity_list" :key="item.id">
                        <!-- <h4>Reward {{ index + 1 }}</h4> -->
                        <div class="activity">
                          <div class="activity_item">
                            <div class="label">
                              <h5>name</h5>
                            </div>
                            <div class="text">
                              <p>
                                {{ item.name }}
                              </p>
                            </div>
                          </div>
                          <div class="activity_item">
                            <div class="label">
                              <h5>settled date</h5>
                            </div>
                            <div class="text">
                              <p>
                                {{ item.date }}
                              </p>
                            </div>
                          </div>
                          <div class="activity_item">
                            <div class="label">
                              <h5>profit amount</h5>
                            </div>
                            <div class="text">
                              <p>
                                {{ item.amount }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>