<template>
  <header>
    <!-- <div id="logo">
      <h1>ETH</h1>
      <img src="@/assets/img/logo.svg" alt="logo">
    </div> -->
    <div id="lang">
      <h5  @click="lang_open=!lang_open">
        <span v-if="lang=='en'">EN</span>
        <span v-else>繁中</span>
      </h5>
      <ul :class="{'active':lang_open}">
        <li @click="switch_lang('en')">
          EN
        </li>
        <li @click="switch_lang('zh_tw')">
          繁中
        </li>
      </ul>
    </div>
    <div id="h_title">
      <img src="@/assets/img/logo.svg" alt="icon">
      <span>ETH-USDT</span>
    </div>
    <button id="h_link" @click="triggerLogin" v-if="!username">
      <img src="@/assets/img/icon/link_icon.svg" alt="icon">
    </button>
    <button id="h_link" v-else>
      {{username}}
    </button>
  </header>
</template>

<script setup>
import { defineProps,ref } from 'vue';
const props = defineProps({
  triggerLogin: Function,
  username: String
});
const lang_open = ref(false);
const lang = ref("en");
lang.value = localStorage.getItem('lang')||'en';

function switch_lang(data) {
  lang_open.value = false;
  localStorage.setItem('lang',data);

  setTimeout(()=>{
    window.location.reload();
  },300)
}
</script>
