import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { createI18n } from "vue-i18n";

// 導入語言文件
import zh_tw from "./lang/zh_tw.json";
import en from "./lang/en.json";

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('lang')||"en", // 默認語言
    messages: {
        zh_tw,
        en,
    },
});

createApp(App).use(store).use(router).use(i18n).mount('#app')
